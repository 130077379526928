import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Button from "@mui/joy/Button"
import { useTranslation } from "react-i18next"
import type { NavigationModalControlProps } from "@levana/theme/NavigationModalDialog"

import type {
  WalletExtensionConnector,
  WalletMobileConnector,
} from "@future/libs/chain/wallet"
import { walletRegistryMap } from "@future/libs/chain/wallets/registry"

export interface WalletConnectMobileSupportProps
  extends NavigationModalControlProps {
  connector: WalletExtensionConnector | WalletMobileConnector
}

const WalletConnectMobileSupport = (props: WalletConnectMobileSupportProps) => {
  const { connector } = props
  const walletRegistry = walletRegistryMap[connector.brand]
  const canInstall =
    walletRegistry.install.android !== undefined ||
    walletRegistry.install.ios !== undefined
  const { t } = useTranslation("common")

  const handleInstall = () => {
    window.open(connector.installUrl(), "_blank")
  }

  return (
    <Stack spacing={2}>
      <Typography level="body-lg">{t("wallet.install.notice")}</Typography>
      {canInstall ? (
        <>
          <Typography level="body-lg">
            {t("wallet.install.available", { walletName: connector.name })}
          </Typography>
          <Button onClick={handleInstall} aria-label="wallet install">
            {t("wallet.install.button")}
          </Button>
        </>
      ) : (
        <Typography level="body-lg">
          {t("wallet.install.unavailable", { walletName: connector.name })}
        </Typography>
      )}
    </Stack>
  )
}

export default WalletConnectMobileSupport
