import Container from "@mui/joy/Container"
import Link from "@mui/joy/Link"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Box from "@mui/joy/Box"
import type { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"

import FooterLayout from "@perps/footer/FooterLayout"
import HeaderLayout from "@perps/header/HeaderLayout"
import marketSearch from "@perps/404/image/marketSearch.png"
import { isProduction } from "@common/env/constants"
import rocket from "@common/geoblocked/image/rocket.svg"
import fixing from "@common/maintenance/image/fixing.png"
import type { MaintenanceMessage } from "@future/target/config"
import { useSecondaryNavList } from "@future/header/navList"
import ErrorContent from "@future/libs/error/ErrorContent"
import type { ContextStoreProp } from "@future/context/store"

import LoadingContent from "./LoadingContent"

export interface LoadingRootContent {
  type: "loading"
  desc: string[]
}

export type InvalidRootContent =
  | { type: "not-found" }
  | { type: "maintenance"; message: MaintenanceMessage | undefined }
  | LoadingRootContent
  | { type: "geoblocked" }
  | { type: "root-error"; error: unknown }
  | { type: "network-data-error"; desc: string; error: unknown }

export type LoadedInvalidRootContent = Exclude<
  InvalidRootContent,
  LoadingRootContent
>

export interface InvalidRootLayoutProps extends ContextStoreProp<"initial"> {
  content: InvalidRootContent
}

const marginTopValue = (content: LoadedInvalidRootContent): number => {
  switch (content.type) {
    case "not-found":
    case "geoblocked":
      return 0
    case "maintenance":
    case "network-data-error":
    case "root-error":
      return -6
  }
}

const InvalidRootLayout = (
  props: PropsWithChildren<InvalidRootLayoutProps>,
) => {
  const withConnectButton = false
  const maxBreakpoint = "lg"
  const secondaryNavList = useSecondaryNavList({
    withConnectButton,
    contextStore: props.contextStore,
  })

  return (
    <>
      <HeaderLayout
        generalList={secondaryNavList.items}
        withConnectButton={withConnectButton}
        contextStore={props.contextStore}
      />
      <Container
        maxWidth={maxBreakpoint}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        {props.content.type === "loading" ? (
          <LoadingContent messages={props.content.desc} />
        ) : (
          <Container
            maxWidth="lg"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "space-between",
            }}
          >
            <Stack sx={{ alignItems: "center" }}>
              <Typography level="h1">
                <Heading content={props.content} />
              </Typography>
              <ShowImage content={props.content} />
              <Box
                sx={{
                  mt: marginTopValue(props.content),
                  zIndex: 1,
                }}
              >
                <InnerContent content={props.content} />
                {props.children}
              </Box>
            </Stack>
          </Container>
        )}
        <FooterLayout />
      </Container>
    </>
  )
}

export default InvalidRootLayout

const Heading = ({
  content,
}: { content: LoadedInvalidRootContent }): string => {
  const { t } = useTranslation("common")
  switch (content.type) {
    case "geoblocked":
      return t("geoblocked.title")
    case "not-found":
      return t("404.title")
    case "maintenance":
      return t("target.maintenance")
    case "root-error":
      return t("errorInRouter.title")
    case "network-data-error":
      return t("errorLoading.title")
  }
}

const ShowImage = ({
  content,
}: { content: LoadedInvalidRootContent }): JSX.Element => {
  switch (content.type) {
    case "geoblocked":
      return (
        <Box
          component="img"
          src={rocket}
          alt="Geoblocked"
          sx={{
            width: { xs: "20rem", sm: "37.5rem" },
          }}
        />
      )
    case "maintenance":
    case "root-error":
    case "network-data-error":
      return (
        <Box
          component="img"
          src={fixing}
          alt="Issue"
          sx={{
            width: 706,
            maxWidth: "100vw",
          }}
        />
      )
    case "not-found":
      return (
        <Box
          component="img"
          src={marketSearch}
          alt="404"
          sx={{
            width: 762,
            maxWidth: "100vw",
          }}
        />
      )
  }
}

const InnerContent = ({
  content,
}: { content: LoadedInvalidRootContent }): JSX.Element => {
  const { t } = useTranslation("common")

  switch (content.type) {
    case "geoblocked":
      return (
        <Typography
          level="h4"
          textAlign="center"
          fontSize={{ xs: "1rem", sm: "1.25rem" }}
        >
          {t("geoblocked.message")}
        </Typography>
      )
    case "not-found":
      return (
        <Typography
          level="h4"
          sx={{ whiteSpace: "pre-line", textAlign: "center" }}
        >
          {t("404.message")}
        </Typography>
      )
    case "maintenance":
      switch (content.message) {
        case "sei-unstable":
          return (
            <>
              The Sei network is currently unstable. Please check back later, or
              join our{" "}
              <Link
                component="a"
                href="https://discord.gg/YaKbsdrgkM"
                target="_blank"
                rel="noreferrer"
                underline="always"
                sx={({ palette }) => ({
                  color: palette.text.primary,
                  textDecorationColor: palette.text.primary,
                })}
                aria-label="discord"
              >
                Discord
              </Link>{" "}
              for updates.
            </>
          )
        // Using default in case an unknown string slips in here
        default:
          return (
            <>
              Levana is currently undergoing maintenance. Please check back
              later, or join our{" "}
              <Link
                component="a"
                href="https://discord.gg/YaKbsdrgkM"
                target="_blank"
                rel="noreferrer"
                underline="always"
                sx={({ palette }) => ({
                  color: palette.text.primary,
                  textDecorationColor: palette.text.primary,
                })}
                aria-label="discord"
              >
                Discord
              </Link>{" "}
              for updates.
            </>
          )
      }
    case "root-error":
      return <ErrorContent error={content.error} showStack={!isProduction} />
    case "network-data-error":
      return (
        <>
          <Typography level="h4">
            {t("errorLoading.body", { desc: content.desc })}
          </Typography>
          <ErrorContent error={content.error} showStack={!isProduction} />
        </>
      )
  }
}
