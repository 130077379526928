export const muiMergeSx = (theme, sx) => {
    if (typeof sx === "function") {
        return sx(theme);
    }
    else if (Array.isArray(sx)) {
        return sx.reduce((accumulator, current) => {
            return Object.assign(accumulator, muiMergeSx(theme, current));
        }, {});
    }
    else if (typeof sx === "object") {
        return sx;
    }
    return {};
};
