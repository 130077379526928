import { useTranslation } from "react-i18next"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana/theme/NavigationModal"

import type { ContextStoreProp } from "@future/context/store"

import WalletConnectRoot from "../WalletConnectRoot"

const WalletConnectModal = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("common")

  return (
    <NavigationModal rootId={WalletConnectRoot.name}>
      {(modal) => (
        <NavigationModalDynamicItem
          id={WalletConnectRoot.name}
          title={t("wallet.dialog.title")}
          canClose
        >
          <WalletConnectRoot {...modal} contextStore={props.contextStore} />
        </NavigationModalDynamicItem>
      )}
    </NavigationModal>
  )
}

export default WalletConnectModal
