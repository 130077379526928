import Avatar, { type AvatarProps } from "@mui/joy/Avatar"
import Box from "@mui/joy/Box"
import * as jdenticon from "jdenticon"
import { muiMergeSx } from "@levana/theme/mui"

import EyeIcon from "@future/libs/chain/wallets/viewing/icon"

export interface WalletAvatarProps extends Omit<AvatarProps, "children"> {
  address: string
  viewing?: boolean
}

const cache: Record<string, string> = {}

const WalletAvatar = (props: WalletAvatarProps) => {
  const { address, viewing, sx, ...joyProps } = props

  const svg = (() => {
    if (cache[address]) {
      return cache[address]
    }

    const svg = jdenticon.toSvg(address, 100, {
      hues: [7, 29, 148, 234, 277],
    })
    cache[address] = svg
    return svg
  })()

  return (
    <Avatar
      {...joyProps}
      sx={[{ borderWidth: { sm: 0 } }, (theme) => muiMergeSx(theme, sx)]}
    >
      <Box
        // biome-ignore lint:
        dangerouslySetInnerHTML={{ __html: svg }}
        sx={{
          width: "100%",
          height: "100%",
          "& > svg": {
            width: "100%",
            height: "100%",
          },
        }}
      />
      {viewing && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EyeIcon
            sx={({ vars }) => ({
              width: "80%",
              height: "80%",
              fill: vars.palette.text.primary,
              m: 0,
            })}
          />
        </Box>
      )}
    </Avatar>
  )
}

export default WalletAvatar
