import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from "@mui/joy/CssBaseline";
import GlobalStyles from "@mui/joy/GlobalStyles";
import { checkboxClasses } from "@mui/joy/Checkbox";
import { inputClasses } from "@mui/joy/Input";
import { sliderClasses } from "@mui/joy/Slider";
import { sheetClasses } from "@mui/joy/Sheet";
import { listItemButtonClasses } from "@mui/joy/ListItemButton";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import { defaultThemeMode } from ".";
import BreakpointDetection from "./BreakpointDetection";
import CheckedIcon from "./icons/CheckedIcon";
import UncheckedIcon from "./icons/UncheckedIcon";
const purple = {
    50: "#fcf8ff",
    100: "#f0deff",
    200: "#d8abff",
    300: "#c078ff",
    400: "#9c2bff",
    500: "#7600dd",
    600: "#5b00aa",
    700: "#400077",
    800: "#250044",
    900: "#17002b",
};
const green = {
    50: "#f0faf7",
    100: "#ddf4ec",
    200: "#b7e7d6",
    300: "#90dbc0",
    400: "#6aceaa",
    500: "#44c194",
    600: "#349e78",
    700: "#27785b",
    800: "#1a523e",
    900: "#0e2b21",
};
const red = {
    50: "#fef2f7",
    100: "#fbdbe8",
    200: "#f8c4da",
    300: "#f297bd",
    400: "#ed69a0",
    500: "#e52574",
    600: "#c0175d",
    700: "#921247",
    800: "#650c31",
    900: "#4e0926",
};
const yellow = {
    50: "#fff1d5",
    100: "#ffeac0",
    200: "#ffe3ab",
    300: "#ffd482",
    400: "#ffc658",
    500: "#FFB82E",
    600: "#cc9325",
    700: "#996e1c",
    800: "#664a12",
    900: "#332509",
};
const gray = {
    50: "#F2F1F4",
    100: "#E5E3E8",
    200: "#CCC7D1",
    300: "#B2ACB9",
    400: "#9992A0",
    500: "#807887",
    600: "#675F6D",
    700: "#4D4653",
    800: "#332E38",
    900: "#1A161D",
};
const dark = (() => {
    const body = "#0E0A12";
    const background = {
        body,
        surface: body,
        popup: gray[900],
        level1: "#1D1A21",
        level2: "#26222A",
        level3: "#39343E",
        level4: "#16111A",
        tooltip: gray[600],
        backdrop: "rgba(0 0 0 / 0.3)",
        spotlight1: "#211A33",
        spotlight2: "#332C48",
    };
    const text = {
        primary: "#FFFFFF",
        secondary: "#B4AFB7",
        tertiary: "#5C595E",
    };
    const divider = "#3E3B41";
    const disabled = "#868388";
    const primary = Object.assign(Object.assign({}, purple), { solidBg: purple[500], solidHoverBg: purple[400], solidActiveBg: purple[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: purple[400] });
    const success = Object.assign(Object.assign({}, green), { solidBg: green[500], solidHoverBg: green[400], solidActiveBg: green[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: green[500] });
    const warning = Object.assign(Object.assign({}, yellow), { solidBg: yellow[500], solidHoverBg: yellow[400], solidActiveBg: yellow[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: yellow[400] });
    const danger = Object.assign(Object.assign({}, red), { solidBg: red[500], solidHoverBg: red[400], solidActiveBg: red[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: red[500] });
    const neutral = Object.assign(Object.assign({}, gray), { solidBg: gray[500], solidHoverBg: gray[400], solidActiveBg: gray[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: text.primary, outlinedBorder: divider });
    return {
        palette: {
            background,
            text,
            divider,
            primary,
            neutral,
            danger,
            success,
            warning,
            focusVisible: "none",
        },
    };
})();
const light = (() => {
    const body = "#FEFDFC";
    const background = {
        body,
        surface: body,
        popup: "#FFFFFF",
        level1: "#F6F6F6",
        level2: "#EEEEEE",
        level3: "#DEDEDE",
        level4: "#F5F5F5",
        tooltip: gray[500],
        backdrop: "rgba(255 255 255 / 0.3)",
        spotlight1: "#e3e1f7",
        spotlight2: "#d6d2f2",
    };
    const text = {
        primary: "#39343E",
        secondary: "#788099",
        tertiary: "#5C595E",
    };
    const divider = "#D5DBE1";
    const disabled = "#C2BCC4";
    const primary = Object.assign(Object.assign({}, purple), { solidBg: purple[500], solidHoverBg: purple[400], solidActiveBg: purple[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: purple[400] });
    const success = Object.assign(Object.assign({}, green), { solidBg: green[500], solidHoverBg: green[400], solidActiveBg: green[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: green[500] });
    const warning = Object.assign(Object.assign({}, yellow), { solidBg: yellow[500], solidHoverBg: yellow[400], solidActiveBg: yellow[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: yellow[700] });
    const danger = Object.assign(Object.assign({}, red), { solidBg: red[500], solidHoverBg: red[400], solidActiveBg: red[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: red[500] });
    const neutral = Object.assign(Object.assign({}, gray), { solidBg: gray[500], solidHoverBg: gray[400], solidActiveBg: gray[600], solidDisabledBg: disabled, solidDisabledColor: text.primary, plainColor: text.primary, outlinedBorder: divider });
    return {
        palette: {
            background,
            text,
            divider,
            primary,
            neutral,
            danger,
            success,
            warning,
            focusVisible: "none",
        },
    };
})();
const theme = extendTheme({
    cssVarPrefix: "",
    colorSchemes: {
        light,
        dark,
    },
    typography: {
        h1: {
            fontSize: "var(--fontSize-xl7)",
            fontWeight: "var(--fontWeight-lg)",
        },
        h2: {
            fontSize: "var(--fontSize-xl3)",
            fontWeight: "var(--fontWeight-xl)",
        },
        h3: {
            fontSize: "var(--fontSize-xl2)",
            fontWeight: "var(--fontWeight-md)",
        },
        h4: {
            fontSize: "var(--fontSize-lg)",
            fontWeight: "var(--fontWeight-md)",
        },
        "title-lg": {
            fontSize: "var(--fontSize-md)",
            fontWeight: "var(--fontWeight-lg)",
        },
        "title-md": {
            fontSize: "var(--fontSize-sm)",
            fontWeight: "var(--fontWeight-lg)",
        },
        "title-sm": {
            fontSize: "var(--fontSize-xs)",
            fontWeight: "var(--fontWeight-lg)",
        },
        "body-lg": {
            fontSize: "var(--fontSize-md)",
        },
        "body-md": {
            fontSize: "var(--fontSize-sm)",
        },
        "body-sm": {
            fontSize: "var(--fontSize-xs)",
            fontWeight: "var(--fontWeight-md)",
        },
        "body-xs": {
            fontSize: "var(--fontSize-xs2)",
            fontWeight: "var(--fontWeight-md)",
        },
        caption: {
            fontSize: "var(--fontSize-xs3)",
            fontWeight: "var(--fontWeight-md)",
        },
    },
    fontSize: {
        /**
         * @link https://utopia.fyi/type/calculator?c=320,14,1.125,1200,18,1.125,8,4,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
         */
        // Step -4: 8.74px → 11.24px
        xs3: "clamp(0.5463rem, 0.4894rem + 0.2841vw, 0.7025rem)",
        // Step -3: 9.83px → 12.64px
        xs2: "clamp(0.6144rem, 0.5505rem + 0.3193vw, 0.79rem)",
        // Step -2: 11.06px → 14.22px
        xs: "clamp(0.6913rem, 0.6194rem + 0.3591vw, 0.8888rem)",
        // Step -1: 12.44px → 16.00px
        sm: "clamp(0.7775rem, 0.6966rem + 0.4045vw, 1rem)",
        // Step 0: 14.00px → 18.00px
        md: "clamp(0.875rem, 0.7841rem + 0.4545vw, 1.125rem)",
        // Step 1: 15.75px → 20.25px
        lg: "clamp(0.9844rem, 0.8821rem + 0.5114vw, 1.2656rem)",
        // Step 2: 17.72px → 22.78px
        xl: "clamp(1.1075rem, 0.9925rem + 0.575vw, 1.4238rem)",
        // Step 3: 19.93px → 25.63px
        xl2: "clamp(1.2456rem, 1.1161rem + 0.6477vw, 1.6019rem)",
        // Step 4: 22.43px → 28.83px
        xl3: "clamp(1.4019rem, 1.2564rem + 0.7273vw, 1.8019rem)",
        // Step 5: 25.23px → 32.44px
        xl4: "clamp(1.5769rem, 1.413rem + 0.8193vw, 2.0275rem)",
        // Step 6: 28.38px → 36.49px
        xl5: "clamp(1.7738rem, 1.5894rem + 0.9216vw, 2.2806rem)",
        // Step 7: 31.93px → 41.05px
        xl6: "clamp(1.9956rem, 1.7884rem + 1.0364vw, 2.5656rem)",
        // Step 8: 35.92px → 46.18px
        xl7: "clamp(2.245rem, 2.0118rem + 1.1659vw, 2.8863rem)",
    },
    fontWeight: {
        sm: 300,
        md: 500,
        lg: 600,
        xl: 700,
    },
    radius: {
        xs: "2px",
        sm: "6px",
        md: "8px",
        lg: "12px",
        xl: "24px",
    },
    zIndex: {
        snackbar: 1000000, // Above Zendesk button
    },
    components: {
        JoyAvatar: {
            styleOverrides: {
                root: {
                    "--variant-borderWidth": "2px",
                },
            },
        },
        JoyBadge: {
            styleOverrides: {
                root: {
                    [`.${sheetClasses.colorNeutral} &`]: {
                        "--Badge-ringColor": "var(--palette-background-level2)",
                    },
                    [`.${sheetClasses.colorNeutral} .${listItemButtonClasses.colorNeutral}:hover &`]: {
                        "--Badge-ringColor": "var(--palette-neutral-plainHoverBg)",
                    },
                },
            },
        },
        JoyButton: {
            styleOverrides: {
                root: ({ ownerState, theme: { vars } }) => (Object.assign(Object.assign({ "--variant-borderWidth": "2px" }, (ownerState.variant === "plain"
                    ? {
                        paddingInline: "0.5rem",
                        "&:hover": Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (ownerState.color === "primary" && {
                            color: vars.palette.primary[300],
                        })), (ownerState.color === "success" && {
                            color: vars.palette.success[400],
                        })), (ownerState.color === "warning" && {
                            color: vars.palette.warning[400],
                        })), (ownerState.color === "danger" && {
                            color: vars.palette.danger[400],
                        })), (ownerState.color === "neutral" && {
                            color: vars.palette.neutral[400],
                        })),
                        "&:active": Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (ownerState.color === "primary" && {
                            color: vars.palette.primary[500],
                        })), (ownerState.color === "success" && {
                            color: vars.palette.success[600],
                        })), (ownerState.color === "warning" && {
                            color: vars.palette.warning[600],
                        })), (ownerState.color === "danger" && {
                            color: vars.palette.danger[600],
                        })), (ownerState.color === "neutral" && {
                            color: vars.palette.neutral[200],
                        })),
                        "&:hover, &:active": {
                            backgroundColor: "transparent",
                        },
                    }
                    : Object.assign(Object.assign(Object.assign({}, (ownerState.size === "sm" && {
                        paddingInline: "1rem",
                    })), (ownerState.size === "md" && {
                        paddingInline: "1.5rem",
                    })), (ownerState.size === "lg" && {
                        paddingInline: "2rem",
                    })))), (ownerState.size === "lg" && {
                    borderRadius: vars.radius.md,
                }))),
            },
        },
        JoyCard: {
            styleOverrides: {
                root: ({ ownerState, theme }) => (Object.assign(Object.assign({ "--variant-borderWidth": "2px" }, (ownerState.variant === "outlined" && {
                    backgroundColor: theme.vars.palette.background.level1,
                })), (ownerState.variant === "soft" && {
                    backgroundColor: theme.vars.palette.background.level2,
                }))),
            },
        },
        JoyCheckbox: {
            defaultProps: {
                variant: "plain",
                checkedIcon: _jsx(CheckedIcon, {}),
                uncheckedIcon: _jsx(UncheckedIcon, {}),
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    "&:hover": {
                        color: theme.vars.palette.text.secondary,
                    },
                }),
                checkbox: ({ ownerState, theme }) => ({
                    backgroundColor: "unset",
                    [`&.${checkboxClasses.checked}`]: {
                        "--Icon-color": theme.vars.palette.success[500],
                        "&:hover": {
                            "--Icon-color": theme.vars.palette.success[600],
                        },
                        "&:active": {
                            "--Icon-color": theme.vars.palette.success[700],
                        },
                    },
                    "&:hover": Object.assign({ backgroundColor: "unset" }, (!ownerState.checked &&
                        ownerState.color && Object.assign({}, (theme.palette.mode === "dark"
                        ? {
                            "--Icon-color": theme.vars.palette[ownerState.color][600],
                        }
                        : {
                            "--Icon-color": theme.vars.palette[ownerState.color][300],
                        })))),
                    "&:active": Object.assign({ backgroundColor: "unset" }, (!ownerState.checked &&
                        ownerState.color && Object.assign({}, (theme.palette.mode === "dark"
                        ? {
                            "--Icon-color": theme.vars.palette[ownerState.color][700],
                        }
                        : {
                            "--Icon-color": theme.vars.palette[ownerState.color][200],
                        })))),
                }),
                label: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
        },
        JoyChip: {
            styleOverrides: {
                root: {
                    "--variant-borderWidth": "2px",
                },
            },
        },
        JoyCircularProgress: {
            styleOverrides: {
                root: ({ ownerState, theme: { vars } }) => (Object.assign({}, (ownerState.variant === "soft" && Object.assign({ "--CircularProgress-trackColor": vars.palette.background.level2 }, (ownerState.color === "primary" && {
                    "--CircularProgress-progressColor": vars.palette.primary[500],
                }))))),
            },
        },
        JoyFormHelperText: {
            styleOverrides: {
                root: ({ theme: { vars } }) => ({
                    fontSize: vars.fontSize.xs2,
                    fontWeight: vars.fontWeight.md,
                    color: `var(--FormHelperText-color, ${vars.palette.text.secondary})`,
                }),
            },
        },
        JoyIconButton: {
            styleOverrides: {
                root: {
                    "--variant-borderWidth": "2px",
                },
            },
        },
        JoyInput: {
            styleOverrides: {
                root: {
                    "--variant-borderWidth": "2px",
                },
                input: ({ theme: { vars } }) => ({
                    "::placeholder": {
                        color: vars.palette.text.secondary,
                        opacity: 1,
                    },
                    [`&.${inputClasses.disabled}::placeholder`]: {
                        color: vars.palette.text.tertiary,
                    },
                }),
            },
        },
        JoyLink: {
            styleOverrides: {
                root: ({ ownerState, theme }) => (Object.assign({}, (ownerState.variant === undefined && Object.assign({}, (ownerState.color === "primary" && {
                    "--color": theme.palette.mode === "dark" ? purple[200] : purple[600],
                    "--variant-plainColor": "var(--color)",
                    "--variant-outlinedBorder": "var(--color)",
                }))))),
            },
        },
        JoyModalClose: {
            styleOverrides: {
                root: {
                    "--variant-borderWidth": "2px",
                },
            },
        },
        JoyModalDialog: {
            styleOverrides: {
                root: ({ theme: { vars, spacing } }) => ({
                    "--ModalDialog-maxWidth": "550px",
                    "--ModalDialog-minWidth": "550px",
                    "--ModalDialog-radius": vars.radius.xl,
                    "--ModalContent-maxWidth": "400px",
                    "--ModalContent-padding": spacing(3),
                    backgroundColor: vars.palette.background.surface,
                    padding: 0,
                }),
            },
        },
        JoyRadio: {
            styleOverrides: {
                root: {
                    "& > svg": {
                        display: "block",
                    },
                },
            },
        },
        JoyRadioGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: "unset",
                },
            },
        },
        JoySheet: {
            defaultProps: {
                variant: "solid",
            },
            styleOverrides: {
                root: ({ ownerState, theme: { vars } }) => (Object.assign(Object.assign({ borderRadius: vars.radius.md, overflow: "hidden" }, (ownerState.variant === "plain" && {
                    borderRadius: 0,
                })), (ownerState.variant === "solid" && {
                    backgroundColor: vars.palette.background.level1,
                    "--palette-background-surface": vars.palette.background.level3,
                }))),
            },
        },
        JoySlider: {
            styleOverrides: {
                root: ({ theme: { vars } }) => ({
                    "--Slider-railBackground": vars.palette.background.level3,
                    "--Slider-thumbBackground": "var(--Slider-railBackground)",
                    [`&.${sliderClasses.disabled}, &:hover, &:active`]: {
                        "--Slider-railBackground": vars.palette.background.level3,
                    },
                    [`&.${sliderClasses.sizeLg}`]: {
                        "--Slider-trackSize": "8px",
                    },
                }),
                track: {
                    "&[style*='right:']": {
                        borderRadius: "0 var(--Slider-trackRadius) var(--Slider-trackRadius) 0",
                    },
                },
                thumb: {
                    [`.${sliderClasses.sizeLg} &:before`]: {
                        borderWidth: "calc((var(--Slider-thumbWidth) - var(--Slider-trackSize)) / 2)",
                    },
                    "&[style*='right:']": {
                        transform: "translate(50%, -50%)",
                    },
                },
            },
        },
        JoyStack: {
            defaultProps: {
                useFlexGap: true,
            },
        },
        JoyStepIndicator: {
            styleOverrides: {
                root: ({ ownerState, theme: { vars } }) => (Object.assign(Object.assign({}, (ownerState.variant === "outlined" && {
                    "--variant-borderWidth": "2px",
                })), (ownerState.color === "primary" && {
                    "--variant-outlinedBorder": vars.palette.primary[500],
                }))),
            },
        },
        JoyTab: {
            styleOverrides: {
                root: ({ ownerState, theme: { vars } }) => (Object.assign(Object.assign({ borderRadius: vars.radius.sm, color: vars.palette.text.secondary }, (ownerState.selected && {
                    borderBottomLeftRadius: "unset",
                    borderBottomRightRadius: "unset",
                    color: vars.palette.text.primary,
                })), { "&.Mui-selected": {
                        backgroundColor: "unset",
                    } })),
            },
        },
        JoyTabList: {
            styleOverrides: {
                root: {
                    boxShadow: "unset",
                },
            },
        },
        JoyTooltip: {
            defaultProps: {
                placement: "top",
                variant: "plain",
            },
            styleOverrides: {
                root: ({ ownerState, theme: { vars, spacing } }) => (Object.assign({ "--Tooltip-arrowSize": spacing(2), borderRadius: vars.radius.lg, padding: spacing(2) }, (ownerState.variant === "plain" &&
                    ownerState.color === "neutral" && {
                    backgroundColor: vars.palette.background.spotlight1,
                }))),
            },
        },
        JoyTypography: {
            styleOverrides: {
                root: ({ ownerState, theme: { vars } }) => (Object.assign(Object.assign({}, (ownerState.level === "title-md" &&
                    ownerState.color === "neutral" && {
                    color: vars.palette.text.primary,
                })), (ownerState.level === "body-xs" &&
                    ownerState.color === "neutral" && {
                    color: vars.palette.text.primary,
                }))),
            },
        },
    },
});
const ThemeProvider = (props) => {
    const { children } = props;
    return (_jsxs(CssVarsProvider, { theme: theme, defaultMode: defaultThemeMode, children: [_jsx(CssBaseline, {}), _jsx(GlobalStyles, { styles: {
                    "#root": {
                        minHeight: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                    },
                    ul: {
                        paddingInlineStart: "1.5rem",
                    },
                } }), _jsx(BreakpointDetection, {}), children] }));
};
export default ThemeProvider;
